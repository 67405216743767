<template>
  <div
    class="container"
    v-title
    id="workBillView"
    :data-title="$t('i18n.workOrderManagement')"
  >
    <div class="text-right">
      <el-button
        v-if="inspectStatus == 10 && pppp"
        class="el-button--add"
        @click="dialogVisible = true"
      >
        {{ $t("i18n.inspect") }}
      </el-button>
      <el-button
        v-if="detail.billstatus == 50"
        class="el-button--export"
        @click="exportBillOne"
      >
        {{ $t("i18n.export") }}
      </el-button>
    </div>
    <div id="view" v-if="innerHtml">
      <div id="displayStatus" v-if="inspectStatus != 10 && inspectStatus != 0">
        <p class="status">{{ setInspectStatus() }}</p>
        <p class="specs">
          {{ detail.inspectDate }} {{ detail.inspectUser }}
          {{ $t("i18n.audit") }}
        </p>
      </div>
      <div class="con" @click="imgClick" v-html="innerHtml"></div>
    </div>
    <el-dialog
      :title="$t('i18n.updateInspectStatus')"
      v-model="dialogVisible"
      destroy-on-close
    >
      <el-form :model="form" label-width="80px">
        <el-form-item :label="$t('i18n.inspectStatus') + '：'">
          <el-radio-group v-model="form.inspectStatus">
            <template v-for="item in inspectStatusData" :key="item.value">
              <el-radio v-if="parseInt(item.value) > 10" :label="item.value" >
              {{ $t("i18n." + item.code) }}
            </el-radio>
            </template>
          </el-radio-group>
        </el-form-item>
        <el-form-item>
          <span style="color: red">{{ text }}</span>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="dialogVisible = false">
          {{ $t("i18n.cancel") }}
        </el-button>
        <el-button type="primary" @click="setInspection">
          {{ $t("i18n.confirm") }}
        </el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { reactive, toRefs, getCurrentInstance } from "vue";
import { useI18n } from "vue-i18n";
// import { useRoute } from "vue-router";
import { useRouter, useRoute } from "vue-router";
import store from "../../utils/store";
export default {
  name: "GenerateWorkBillView",
  setup(props, { emit }) {
    emit("public_header", true);
    const { t } = useI18n();
    const route = useRoute();
    const { proxy } = getCurrentInstance();
    const state = reactive({
      inspectStatusData: [],
      inspectStatus: "",
      pppp: proxy.$defined.btnPermission('工单核查'),
      id: route.query.id,
      detail: {},
      innerHtml: "",
      dialogVisible: false,
      form: {
        inspectStatus: "20",
      },
      text: "",
    });

    const getInspectStatus = () => {
      proxy.$api.common.getLineageByCode("inspectStatus").then((res) => {
        state.inspectStatusData = res.data.lineages;
      });
    };
    getInspectStatus();

    const getResult = async () => {
      let { description } = await proxy.$api.maintenance.getResult(state.id);
      state.text = description;
    };

    const getStatusDetail = async () => {
      let { data } = await proxy.$api.maintenance.getStatusDetail(state.id);
      state.detail = data;
      state.inspectStatus = data.inspectStatus;
    };

    const setInspectStatus = () => {
      let arr = state.inspectStatusData.filter((obj) => {
        return obj.value == state.inspectStatus;
      });
      return arr.length > 0 ? t("i18n." + arr[0].code) : "";
    };

    const exportBillOne = async () => {
      // 导出单个工单
      let res = await proxy.$api.maintenance.exportBillOne(state.id);
      proxy.$defined.exportFunc(res);
    };

    const getBillReport = async () => {
      let { data } = await proxy.$api.maintenance.getBillReport(state.id);
      state.innerHtml = data;
    };

    const setInspection = async () => {
      await proxy.$api.maintenance.setInspection(state.id, state.form);
      state.dialogVisible = false;
      init();
    };

    const imgClick = (e) => {
      if (e.target.tagName == "img" || e.target.tagName == "IMG") {
        if (
          e.target.getAttribute("width") ==
            e.target.getAttribute("default_width") &&
          e.target.getAttribute("height") ==
            e.target.getAttribute("default_height")
        ) {
          e.target.setAttribute("width", e.target.getAttribute("width") * 3);
          e.target.setAttribute("height", e.target.getAttribute("height") * 3);
        } else {
          e.target.setAttribute(
            "width",
            e.target.getAttribute("default_width")
          );
          e.target.setAttribute(
            "height",
            e.target.getAttribute("default_height")
          );
        }
      }
    };

    const init = () => {
      if (route.query.token) {
        console.log("=========",route.query.token);
        localStorage.setItem("token", route.query.token);
        store.commit("auth_success", route.query.token, "");
      }
      emit("public_header", !state.isMobile);
      getStatusDetail();
      getBillReport();
      getResult();
    };

    init();

    return {
      ...toRefs(state),
      setInspectStatus,
      setInspection,
      exportBillOne,
      imgClick,
    };
  },
};
</script>

<style lang="scss">
#workBillView {
  width: 655px;
  margin: 0 auto;
  #view {
    position: relative;
    #displayStatus {
      position: absolute;
      top: 40px;
      right: -40px;
      z-index: 9;
      color: #3498db;
      font-size: 50px;
      transform: rotateZ(35deg);
      text-align: center;
      .specs {
        font-size: 15px;
        margin-top: 15px;
      }
    }
    #displayStatus.error {
      color: #d9345d;
    }
    .con {
      text-align: center;
      table {
        margin: 15px auto 0;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  #workBillView {
    margin: -20px 5px;
    overflow: auto;
    width: unset;
  }
}
</style>
